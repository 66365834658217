import { render, staticRenderFns } from "./default-without-footer.vue?vue&type=template&id=15a9d758&"
import script from "./default-without-footer.vue?vue&type=script&lang=js&"
export * from "./default-without-footer.vue?vue&type=script&lang=js&"
import style0 from "./default-without-footer.vue?vue&type=style&index=0&id=15a9d758&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzOffline: require('/opt/airport.kg/node_modules/razlet-ui/src/components/offline/index.vue').default,RzNotificationCenter: require('/opt/airport.kg/node_modules/razlet-ui/src/components/notification-center/index.vue').default,BaseHeader: require('/opt/airport.kg/src/components/base/header/index.vue').default,BaseOrnamentBg: require('/opt/airport.kg/src/components/base/ornament-bg.vue').default})
