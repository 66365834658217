
export default {
  destroyed() {
    if (this.$nuxt) this.$nuxt.$emit('route-changed');
  },
  head () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
};
